var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-4",staticStyle:{"background":"white"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}},[_c('filterInventory',{on:{"filtersItem":_vm.filtersItem}})],1),_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Productos | Servicios ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, _vm.totalItems]},"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.editedItem=item, _vm.editDialog=true}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price))+" ")]}},{key:"item.price_two",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price_two))+" ")]}},{key:"item.price_three",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price_three))+" ")]}},{key:"item.price_four",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price_four))+" ")]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.cost))+" ")]}}],null,true)}),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"bottom":"","color":"#e25200","dark":"","fab":"","fixed":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('createFelItem',{on:{"closeDialogItem":_vm.closeDialogItem}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('editFelItem',{attrs:{"editedItem":_vm.editedItem},on:{"closeDialogItem":_vm.closeDialogItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }